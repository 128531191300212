import React from 'react'

const Header = () => {
  return (
    <header className="header-section">
  <div className="header-bottom" style={{backgroundColor: '#000000'}}>
    <div className="container" style={{backgroundColor: '#000000'}}>
      <nav className="navbar navbar-expand-xl" >
        <a className="site-logo site-title" href="/"><img src="https://res.cloudinary.com/dzxyvdq14/image/upload/w_166,h_79,c_fit/triumphfx-logo.png" alt="site-logo" /></a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span className="menu-toggle" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav main-menu ml-auto mr-4">
            <li><a style={{color: '#fff'}} href="/about">About</a></li>
            <li><a style={{color: '#fff'}} href="/real-estate">Real Estate</a></li>
            <li><a style={{color: '#fff'}} href="/sports">Sports</a></li>
            <li><a style={{color: '#fff'}} href="/gold-mine">Gold Mine</a></li>
            {/* <li><a style={{color: '#fff'}} href="/affiliate">Affiliate</a></li> */}
            <li><a style={{color: '#fff'}} href="/packages">Packages</a></li>              
            <li><a style={{color: '#fff'}} href="/contact">contact</a></li>
          </ul>
          <div className="header-join-part">
            <a type="button" className="btn btn-primary btn-hover" href="https://account.triumphs-fx.com">join us</a>
          </div>
        </div>
      </nav>
    </div>
  </div>{/* header-bottom end */}
</header>

  )
}

export default Header
